<!-- Localized -->
<template>
<checkbox-icon
  class="icon"
  :class="{active: !!calculatedValue}"
  @click="toggle"
/>
</template>

<script>
import checkboxIcon from '@/assets/icons/dashboard/actions/check.svg';

export default {
  name: 'checkbox',
  components: {
    checkboxIcon,
  },
  props: {
    isAck: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      calculatedValue: false,
    }
  },
  created() {
    this.calculatedValue = this.isAck;
  },
  watch: {
    isAck(val) {
      this.calculatedValue = val;
    },
  },
  methods: {
    toggle() {
      this.calculatedValue = !this.calculatedValue;
      this.$emit('input', this.calculatedValue);
    },
  }
}
</script>

<style lang="scss" scoped>
.icon {
  &:not(.active) {
    fill: #AFAFAF;
  }
}
</style>